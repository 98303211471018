@font-face {
  font-family: "iconfont"; /* Project id 4719000 */
  src: url('iconfont.woff2?t=1731641257080') format('woff2'),
       url('iconfont.woff?t=1731641257080') format('woff'),
       url('iconfont.ttf?t=1731641257080') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-jiangdichengben:before {
  content: "\e6cc";
}

.icon-manyi:before {
  content: "\e8db";
}

.icon-zhiliang-xianxing:before {
  content: "\e948";
}

.icon-lvseditanjishuguanli:before {
  content: "\e643";
}

.icon-zhunshi:before {
  content: "\e6af";
}

.icon-guanbi:before {
  content: "\e7e8";
}

.icon-xiazai:before {
  content: "\e61b";
}

.icon-ditu-dibiao:before {
  content: "\e609";
}

.icon-bofang:before {
  content: "\e663";
}

.icon-Down-Left:before {
  content: "\e76c";
}

.icon-jianli:before {
  content: "\e7b5";
}

.icon-fengxianceping:before {
  content: "\e62c";
}

.icon-shaixuan:before {
  content: "\e62b";
}

.icon-icon_tijian:before {
  content: "\e606";
}

.icon-icon_luyongguanli_luyongtijiao:before {
  content: "\e73e";
}

.icon-diqiu:before {
  content: "\e642";
}

.icon-24gl-telephone2:before {
  content: "\e953";
}

.icon-weixin:before {
  content: "\e65f";
}

.icon-Upward:before {
  content: "\e608";
}

.icon-arrow-right:before {
  content: "\e600";
}

.icon-middle-left:before {
  content: "\e601";
}

.icon-middle-right:before {
  content: "\e602";
}

.icon-small-btm:before {
  content: "\e603";
}

.icon-small-top:before {
  content: "\e604";
}

