@import "./utils";
@import "~jason-css/src/responsive/index";
@import "~swiper/swiper-bundle.min.css";
@import '~@fancyapps/fancybox/dist/jquery.fancybox.min.css';
@import './animate.css';
@import "../fonts/iconfont.css";

@font-face {
  font-family: 'Nexa_Bold';
  src: url('../fonts/Nexa_Bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

*{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  outline:none;
}
html{
  font-size: 62.5%;
}
body {
  font-family: "PingFang SC","Microsoft YaHei";
  line-height: 1.2;
  position: relative;
  color: $color-text;
  background-color: #fff;
  @include res(font-size, 12px);
}
.comp-root{
  overflow-x: hidden;
  @include res(font-size,.18rem,(md:.28rem,sm:.38rem,xs:.3rem));
}

.t-center{
  text-align: center;
}

//主体内容部分宽度
.container {
  @include res(padding-left,4.16vw,(md:1rem,xs:.3rem));
  @include res(padding-right,4.16vw,(md:1rem,xs:.3rem));
}

.common-more{
  display: inline-flex;
  align-items: center;
  color: #000;
  @include res(font-size, .18rem,(md:.28rem,sm:.38rem,xs:.28rem));
  .icon{
    border-radius: 50%;
    border-style: solid;
    border-color: $color-main-dark;
    position: relative;
    overflow: hidden;
    transition: color .3s, background-color .3s;
    @include res(border-width, .02rem,(md:2px));
    @include res(width,.5rem,(md:.7rem,sm:.9rem,xs:.75rem));
    @include res(height,.5rem,(md:.7rem,sm:.9rem,xs:.75rem));
    @include res(margin-left,.15rem);
    &::before,&::after{
      content: '\e600';
      font-family: 'iconfont';
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: transform .3s;
      @include res(font-size, .22rem,(md:.34rem,sm:.42rem,xs:.36rem));
    }
    &::before{
      position: absolute;
      top: 0;
      right: 100%;
    }
  }
  &:hover{
    .icon{
      background-color: $color-main-dark;
      color: #fff;
      &::before,&::after{
        transform: translateX(100%);
      }
    }
  }
}

.common-more2{
  display: inline-flex;
  align-items: center;
  border-radius:50px;
  background-color: $color-main-dark;
  position: relative;
  white-space: nowrap;
  box-sizing: border-box;
  @include res(font-size,.18rem,(md:.28rem,sm:.38rem,xs:.28rem));
  @include res(padding-left,.38rem,(md:.45rem));
  @include res(padding-right,.15rem,(md:.23rem,sm:.26rem,xs:.22rem));
  @include res(width,1.88rem,(md:2.8rem,sm:3.4rem,xs:2.8rem));
  @include res(height,.54rem,(md:.8rem,sm:1rem,xs:.8rem));
  .t1{
    color: #fff;
  }
  .btn-circle{
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    background: #fff;
    border-radius:50px;
    transition: clip-path .65s $anime-bezier,transform .65s $anime-bezier;
    @include res(top,.02rem,(sm:.04rem,xs:.03rem));
    @include res(left,.02rem,(sm:.04rem,xs:.03rem));
    @include res(bottom,.02rem,(sm:.04rem,xs:.03rem));
    @include res(right,.02rem,(sm:.04rem,xs:.03rem));
    @include res(clip-path,inset(.01rem .01rem .01rem calc(100% - 0.49rem) round .5rem),(
      md: inset(.01rem .01rem .01rem calc(100% - .75rem) round .5rem),
      sm: inset(.01rem .01rem .01rem calc(100% - .93rem) round .5rem),
      xs: inset(.01rem .01rem .01rem calc(100% - .75rem) round .5rem),
    ));
    @include res(padding-left,.38rem,(md:.45rem));
    @include res(padding-right,.15rem,(md:.23rem,sm:.26rem,xs:.22rem));
    .t2{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .icon{
        overflow: hidden;
        position: relative;
        flex-shrink: 0;
        &::before,&::after{
          content: '\e600';
          font-family: 'iconfont';
          display: inline-block;
          transition: transform .65s $anime-bezier;
          @include res(font-size,.18rem,(md:.28rem,sm:.38rem,xs:.3rem));
        }
        &::before{
          position: absolute;
          top: 0;
          right: 100%;
        }
      }
    }
  }
  &:hover{
    .btn-circle{
      clip-path: inset(0 round .5rem);
      .t2{
        .icon{
          &::before,&::after{
            transform: translateX(100%);
          }
        }
      }
    }
  }
}

.common-title{
  @include res(margin-bottom,.75rem,(xs:.5rem));
  h1{
    color: #000;
    @include res(font-size,.5rem,(md:.6rem,sm:.7rem,xs:.5rem));
  }
  .desc{
    line-height: 1.8;
    @include res(width, 60.2%,(md:100%));
    @include res(font-size, .22rem,(md:.32rem,sm:.42rem,xs:.3rem));
    @include res(margin-top,.45rem,(md:.3rem));
  }
  .flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.has-mask{
  position: relative;
  overflow: hidden;
  &::after{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(00,0,0,.2);
    position: absolute;
    top: 0;
    left: 0;
  }
}
.bg-img{
  display: block;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: transform .3s;
}

.swiper-button-next,.swiper-button-prev{
  transition: opacity .3s;
  background-color: rgba(0,0,0,.5);
  border-radius: 50%;
  color: #fff;
  overflow: hidden;
  @include res(opacity,0,(md:1));
  @include res(width, .47rem,(md:.6rem,sm:.8rem,xs:.7rem));
  @include res(height, .47rem,(md:.6rem,sm:.8rem,xs:.7rem));
  &::after,&::before{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'iconfont';
    transition: transform .3s;
    @include res(font-size, .26rem,(md:.36rem,sm:.56rem,xs:.4rem));
  }
  &.swiper-button-disabled{
    pointer-events: all;
    @include res(opacity,0,(md:.2));
  }
}
.swiper-button-next{
  @include res(right, .15rem,(md:.1rem,sm:.2rem,xs:.1rem));
  &::after,&::before{
    content: '\e602';
  }
  &::before{
    right: 100%;
  }
  &:hover{
    &::after,&::before{
      transform: translateX(100%);
    }
  }
}
.swiper-button-prev{
  @include res(left, .15rem,(md:.1rem,sm:.2rem,xs:.1rem));
  &::after,&::before{
    content: '\e601';
  }
  &::before{
    left: 100%;
  }
  &:hover{
    &::after,&::before{
      transform: translateX(-100%);
    }
  }
}
.swiper-pagination{
  position: static;
  @include res(margin-top, .25rem);
  .swiper-pagination-bullet{
    margin: 0;
    transition: background-color .3s;
    @include res(margin-right,.05rem,(md:5px));
    @include res(width,.1rem,(md:8px));
    @include res(height,.1rem,(md:8px));
    &.swiper-pagination-bullet-active{
      background-color: #000;
    }
  }
  &.swiper-pagination-progressbar{
    height: 1px;
    background-color: #b9b9b9;
    @include res(margin-top, .85rem,(xs:.5rem));
    .swiper-pagination-progressbar-fill{
      height: 3px;
      background-color: #28438b;
      margin-top: -1px;
    }
  }
}
.swiper{
  user-select: none;
}
.lineb{
  display: inline-block;
}