@import "../../assets/styles/utils";

.footer{
  overflow: hidden;
  color: #fff;
  background: url(../../assets/images/footer_bg.png) no-repeat bottom right #10539c;
  @include res(background-size, 5.82rem auto);
  @include res(padding-top,1.2rem);
  @include res(padding-bottom,.6rem);
  @include res(margin-top, 1.25rem);
  .footer-top{
    justify-content: space-between;
    @include res(display,flex,(sm:block));
    .logo{
      flex-shrink: 0;
      @include res(text-align,null,(xs:center));
      @include res(width,30.11%,(sm:100%));
      .img{
        img{
          height: auto;
          @include res(width,3.21rem,(md:4.21rem,sm:5.21rem,xs:4.5rem));
        }
      }
      .t1{
        @include res(font-size, .22rem,(md:.32rem,sm:.42rem,xs:.3rem));
        @include res(margin-top,.45rem);
        @include res(margin-bottom,.15rem);
      }
      .t2{
        opacity: .6;
        text-transform: uppercase;
        line-height: 1.38;
      }
    }
    .footer-nav{
      flex-shrink: 0;
      @include res(width,54%);
      @include res(display,null,(sm:none));
      ul{
        justify-content: space-between;
        @include res(display,flex);
        @include res(font-size, .16rem,(md:.26rem,sm:.36rem,xs:.3rem));
        li{
          .sub-nav{
            @include res(margin-top,.35rem);
            a{
              display: block;
              opacity: .8;
              transition: opacity .3s;
              @include res(margin-bottom,.1rem);
              &:hover{
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
  .footer-info{
    @include res(margin-top,1rem);
    @include res(margin-bottom,1rem);
    .info-title{
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      border-bottom: solid rgba(255,255,255,.2);
      @include res(border-bottom-width, .01rem,(md:1px));
      @include res(margin-bottom,.4rem);
      .t{
        position: relative;
        @include res(padding-bottom,.17rem);
        &::after{
          content: '';
          display: block;
          width: 100%;
          background-color: #fff;
          position: absolute;
          left: 0;
          @include res(bottom, -.02rem,(md:-2px));
          @include res(height,.03rem,(md:3px));
        }
      }
      .backTop{
        background-color: #fff;
        border-radius: 50%;
        position: relative;
        z-index: 1;
        transform: translateY(50%);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        @include res(width,.5rem,(md:.64rem,sm:.8rem,xs:.7rem));
        @include res(height,.5rem,(md:.64rem,sm:.8rem,xs:.7rem));
        .iconfont{
          color: $color-main-dark-text;
          display: block;
          transition: all .3s;
          @include res(font-size,.24rem,(md:.34rem,sm:.44rem,xs:.4rem));
        }
        &:hover{
          .iconfont{
            transform: translateY(-.05rem);
          }
        }
      }
    }
    ul{
      @include res(justify-content,flex-start,(md:space-between));
      @include res(display,flex,(xs:block));
      li{
        @include res(width, 28%,(md:auto));
        &:not(:last-child){
          @include res(margin-bottom,0,(xs:.3rem));
        }
        .t1{
          @include res(font-size, .14rem,(md:.24rem,sm:.34rem,xs:.24rem));
          @include res(margin-bottom,.15rem);
        }
        .t2{
          @include res(font-size, .24rem,(md:.34rem,sm:.44rem,xs:.34rem));
        }
      }
    }
  }
  .copyright{
    justify-content: space-between;
    color: rgba(255,255,255,.6);
    @include res(display,flex);
    @include res(font-size, .16rem,(md:.26rem,sm:.36rem,xs:.24rem));
    a{
      transition: color .3s;
      &:hover{
        color: #fff;
      }
    }
    .site-map{
      @include res(display,null,(md:none));
      @include res(margin-left, .1rem);
    }
  }
}
.aside-social{
  position: fixed;
  bottom: 10%;
  z-index: 100;
  background: rgba(255,255,255,.6);
  border-radius: 50px;
  backdrop-filter: blur(10px);
  box-shadow: 0 0 .1rem rgba(0,0,0,.1);
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s,visibility .3s;
  @include res(padding,.02rem,(md:2px));
  @include res(right,4.16vw,(md:1rem,xs:.3rem));
  &.show{
    opacity: 1;
    visibility: visible;
  }
  .social,.backTop{
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @include res(width,.47rem,(md:.64rem,sm:.8rem,xs:.7rem));
    @include res(height,.47rem,(md:.64rem,sm:.8rem,xs:.7rem));
  }
  .social{
    position: relative;
    transition: background-color .3s;
    margin-bottom: .02rem;
    .iconfont{
      color: #000;
      transition: color .3s;
      @include res(font-size, .26rem,(md:.36rem,sm:.5rem,xs:.4rem));
    }
    .cc{
      position: absolute;
      top: 50%;
      white-space: nowrap;
      transform: translateY(-50%);
      transition: all .3s;
      opacity: 0;
      visibility: hidden;
      box-shadow: 0 0 .15rem rgba(0,0,0,.15);
      @include res(right,140%);
      &::after{
        content: '';
        display: block;
        border-style: solid;
        border-color: transparent transparent transparent #fff;
        border-width: 5px;
        position: absolute;
        left: calc(100% - 1px);
        top: 50%;
        transform: translateY(-50%);
      }
      a{
        display: block;
        background-color: #fff;
        border-radius: .05rem;
        transition: color .3s;
        @include res(padding, .1rem,(md:.14rem,sm:.16rem,xs:.12rem));
        @include res(font-size, .14rem,(md:.24rem,sm:.34rem,xs:.26rem));
        &:hover{
          color: $color-main;
        }
      }
      img{
        display: block;
        height: auto;
        @include res(width, 1rem,(md:1.6rem,sm:2rem));
      }
    }
    &:hover{
      background-color: $color-main;
      .iconfont{
        color: #fff;
      }
      .cc{
        opacity: 1;
        visibility: visible;
        @include res(right,130%);
      }
    }
  }
  .backTop{
    background-color: #fff;
    transition: background-color .3s,color .3s;
    .iconfont{
      display: block;
      transform: translateY(3px);
      transition: transform .3s;
      @include res(font-size, .2rem,(md:.3rem,sm:.4rem,xs:.3rem));
    }
    &:hover{
      background-color: $color-main;
      color: #fff;
      .iconfont{
        transform: translateY(-1px);
      }
    }
  }
}