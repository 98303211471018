@import "../../assets/styles/utils";

header{
  .header{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    z-index: 1000;
    width: 100%;
    transition: all .4s;
    @include res(top,0,(md:0));
    @include res(height,$header-height-base,$header-height-ratio);
    &::before{
      content: '';
      display: block;
      width: 100%;
      background-color: rgba($color-main,.1);
      position: absolute;
      bottom: 0;
      left: 0;
      opacity: 0;
      transition: opacity .3s;
      @include res(height,.01rem,(md:1px));
    }
    &:hover,&.show-menu,&.scroll{
      .white-bg{
        opacity: 1;
      }
      .logo{
        .logo-white{
          opacity: 0;
        }
        .logo-color{
          opacity: 1;
        }
      }
      .right{
        .nav{
          ul li .title{color: #000;}
        }
        .right-fun{
          .language{color: #000;}
          .hamburger>div, .hamburger>div:after, .hamburger>div:before, .hamburger>view, .hamburger>view:after, .hamburger>view:before{
            background-color: #000;
          }
        }
      }
    }
    &:hover{
      &::before{
        opacity: 1;
      }
    }
    &.scroll{
      top: 0;
      box-shadow: 0 0 .1rem rgba(0,0,0,.1);
      @include res(height,1rem,$header-height-ratio);
    }
    &.trans{
      top: 0;
      transform: translateY(-100%);
    }
    .white-bg{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255,255,255,.8);
      z-index: -1;
      opacity: 0;
      transition: all .5s;
      box-shadow: 0 0 .1rem rgba(0,0,0,.1);
      backdrop-filter: blur(20px);
    }
    .logo{
      position: absolute;
      @include res(top,50%);
      @include res(transform,translateY(-50%));
      @include res(left,4.16vw,(md:1rem,xs:.3rem));
      img{
        display: block;
        height: auto;
        transition: all .3s;
        @include res(width,2.75rem,(md:3rem,sm:3.6rem,xs:2.8rem));
        &.logo-color{
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
        }
      }
    }
    .right{
      align-items: center;
      height: 100%;
      @include res(display,flex);
      .nav{
        height: 100%;
        @include res(display,block,(md:none));
        ul{
          position: relative;
          align-items: center;
          height: 100%;
          @include res(display,flex);
          li{
            height: 100%;
            position: relative;
            .title{
              height: 100%;
              color: #fff;
              transition: color .3s;
              a{
                height: 100%;
                display: flex;
                align-items: center;
                @include res(padding-left,.24rem);
                @include res(padding-right,.24rem);
              }
            }
            .sub-nav{
              opacity: 0;
              visibility: hidden;
              top: 100%;
              position: absolute;
              top: 90%;
              left: 0;
              white-space: nowrap;
              transition: all .3s;
              @include res(padding-left,.24rem);
              @include res(padding-right,.24rem);
              @include res(padding-top,.24rem);
              @include res(padding-bottom,.24rem);
              a{
                display: block;
                transition: all .3s;
                @include res(padding, .05rem 0);
                &:not(:last-child){
                  @include res(margin-bottom, .1rem);
                }
                &:hover{
                  font-weight: bold;
                  color: $color-main-dark;
                }
              }
            }
            &.hover{
              .title{font-weight: bold;}
              .sub-nav{
                opacity: 1;
                visibility: visible;
                transition-delay: .2s;
                @include res(top, 100%);
              }
            }
          }
        }
      }
      .right-fun{
        position: absolute;
        align-items: center;
        justify-content: flex-end;
        @include res(display,flex);
        @include res(top,50%);
        @include res(transform,translateY(-50%));
        @include res(right,4.16vw,(md:1rem,xs:.3rem));
        .language{
          color: #fff;
          transition: color .3s;
          @include res(font-size, .14rem,(md:.24rem,sm:.34rem,xs:.24rem));
          a{
            display: flex;
            align-items: center;
          }
          .iconfont{
            @include res(font-size,.26rem,(md:.38rem,sm:.5rem,xs:.4rem));
            @include res(margin-right,.1rem);
          }
        }
        .hamburger{
          @include res(margin-left,.5rem,(xs:.3rem));
          @include res(display,none,(md:block));
        }
      }
    }
  }
  .mob-header{
    position: fixed;
    top: 0;
    left: 100%;
    z-index: 998;
    height: 100vh;
    overflow-x: hidden;
    background: rgba(255,255,255,.9);
    background-size: cover;
    transition: all $anime-duration;
    backdrop-filter: blur(10px);
    @include res(padding-top, 3rem,(xs:1.3rem));
    @include res(padding-bottom, 1.5rem,(xs:.5rem));
    @include res(padding-left, 1rem,(xs:.5rem));
    @include res(padding-right, 1rem,(xs:.5rem));
    @include res(width,60%,(sm:80%,xs:100%));
    &.show{
      transform: translateX(-100%);
    }
    ul{
      height: 100%;
      width: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      li{
        border-bottom: 1px solid rgba(0,0,0,.1);
        letter-spacing: .01rem;
        &.active{
          .title .iconfont{
            transform: rotate(90deg);
          }
        }
        .title{
          align-items: center;
          justify-content: space-between;
          text-transform: uppercase;
          @include res(line-height,1.4rem,(xs:1rem));
          @include res(padding-left,.3rem);
          @include res(display,flex);
          @include res(font-size,.4rem,(sm:.5rem,xs:.36rem));
          a{
            flex-grow: 1;
          }
          .iconfont{
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            transition: all $anime-duration;
            transform-origin: center;
            @include res(display,none);
            @include res(font-size,.4rem,(xs:.34rem));
            @include res(width,1.4rem,(xs:1rem));
            @include res(height,1.4rem,(xs:1rem));
          }
        }
        .sub-nav{
          display: none;
          @include res(padding-left, .3rem);
          @include res(padding-right, .3rem);
          @include res(padding-bottom,.3rem);
          &::after{
            content: '';
            display: block;
            clear: both;
          }
          a{
            display: block;
            width: 50%;
            float: left;
            @include res(margin-bottom,.2rem);
          }
        }
      }
    }
  }
  .menu-mask{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.2);
    position: fixed;
    top:0;
    left: 0;
    z-index: 997;
    display: none;
  }
}