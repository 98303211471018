@import "../../assets/styles/utils";

.ny-banner{
  position: relative;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  z-index: 5;
  @include res(height,7.5rem,(xs:4.5rem));
  @include res(margin-bottom,1.3rem,(xs:.7rem));
  .img{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .banner-t{
    color: #fff;
    @include res(margin-bottom,1.55rem,(xs:.7rem));
    h2,h1{
      span{
        display: inline-block;
      }
    }
    h2{
      font-family: 'Nexa_Bold';
      overflow: hidden;
      @include res(font-size,.2rem,(md:.3rem,sm:.4rem,xs:.3rem));
    }
    h1{
      overflow: hidden;
      display: flex;
      align-items: center;
      @include res(letter-spacing,5px,(xs:3px));
      @include res(margin-top, .15rem);
      @include res(font-size,.6rem,(md:.7rem,sm:.8rem,xs:.5rem));
      &::after{
        content: '';
        display: block;
        flex-grow: 1;
        background: rgba(255,255,255,.3);
        @include res(height, .01rem,(md:1px));
        @include res(margin-left,.15rem);
      }
    }
  }
}